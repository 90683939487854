import { render, staticRenderFns } from "./Template1.vue?vue&type=template&id=fe9b5b14&"
import script from "./Template1.vue?vue&type=script&lang=js&"
export * from "./Template1.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/data/jenkins/jobs/management-system-frontend/workspace/ant-design-vue-jeecg/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('fe9b5b14')) {
      api.createRecord('fe9b5b14', component.options)
    } else {
      api.reload('fe9b5b14', component.options)
    }
    module.hot.accept("./Template1.vue?vue&type=template&id=fe9b5b14&", function () {
      api.rerender('fe9b5b14', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/jeecg/JVxeDemo/layout-demo/Template1.vue"
export default component.exports